import React  from "react"
import { Link } from "gatsby"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"

import styles from "./madetoorder.module.scss"

const MadeToOrder3 = ({ location }) => {

  return (
    <>
      <SEO
        title="Made To Order - The Happy Ending"
        description="Made To Order"
        url={location.pathname}
      />

      <header className={styles.header}>
        <nav className={styles.nav}>
          <Heading
            tag="h2"
            level="2"
            className={styles.navHeading}
          >
            Made to Order
          </Heading>
          <ul className={styles.navList}>
            <li>
              <Link to="/made-to-order/chapter-1" className={styles.navLinkPrev}>1</Link>
            </li>
            <li>
              <Link to="/made-to-order/chapter-2" className={styles.navLinkPrev}>2</Link>
            </li>
            <li>
              <Link to="/made-to-order/chapter-3" className={styles.navLinkActive}>3</Link>
            </li>
          </ul>
        </nav>
        <div className={styles.headingContainer3}>
          <h3 className={styles.chapterHeading}>CHAPTER 3</h3>
          <Heading
            tag="h1"
            level="1"
            className={styles.heading}
          >
            The Happy Ending
          </Heading>
        </div>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase3_1_Desktop_1320x.jpg?v=1615114401",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase3_1_Mobile_850x.jpg?v=1615114400",
          }}
          altText="Chapter 3"
        />
      </header>

      <section className={styles.section}>
        <div>
          <p>Great news: The finishing touches have been applied to your shoes at our factory in Italy, and they are currently being buffed for a final shine. Lastly, they will be carefully wrapped (a veritable ceremonial process) and board a plane to our fulfillment center in the States.</p>
        </div>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase3_2_1320x.jpg?v=1615114401",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase3_2_850x.jpg?v=1615114401",
          }}
          altText="Chapter 3 - Part 1"
        />
      </section>

      <section className={styles.section}>
        <div>
          <p>Once they’ve passed through customs and have been checked in, you’ll receive a shipping notification letting you know they’re officially en route to your doorstep. Talk about a happy ending.</p>
        </div>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase3_3_1320x.jpg?v=1615114401",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase3_3_850x.jpg?v=1615114401",
          }}
          altText="Chapter 3 - Part 2"
        />
      </section>
    </>
  )
}

export default MadeToOrder3
